import React, { useState, useEffect, useRef } from "react";
import Title from "../Title/Title";
import Desc from "../Desc/Desc";
import Button from "../Button/Button";
import Paragraph from "../Paragraph/Paragraph";
import styles from "./Result.module.scss";
import classNames from "classnames";
import { SITE_URL } from "../../state/consts";
import { IconButton, Snackbar } from "@mui/material";
import ShareIcon from "@mui/icons-material/Share";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import { FacebookShareButton, WhatsappShareButton } from "react-share";

import { FacebookIcon, WhatsappIcon } from "react-share";

function Result({
  score,
  title,
  text,
  imageSrc,
  posterContainer,
  optionsContainer,
  handlerBack,
}) {
  const [offset, setOffset] = useState(false);
  const [copySuccess, setCopySuccess] = useState("");
  const [showConditions, setShowConditions] = useState(false);
  const textAreaRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setOffset(true);
      } else {
        setOffset(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const ImageURL = SITE_URL + imageSrc;

  // кнопка скопировать
  const [open, setOpen] = useState(false);

  const testClick = () => {
    setOpen(true);
    navigator.clipboard.writeText(window.location.toString());
  };

  function copyToClipboard(e) {
    textAreaRef.current.select();
    console.log(textAreaRef.current.value);
    document.execCommand("copy");
    e.target.focus();
    setCopySuccess(
      <div className={styles.copySuccess}>Tautan berhasil tersalin</div>
    );

    setTimeout(() => {
      setCopySuccess(
        <div className={classNames(styles.copySuccess, styles.displayNone)}>
          Tautan berhasil tersalin
        </div>
      );
    }, "1500");
  }

  const linkRef = useRef(null);

  useEffect(() => {
    function pushUTM() {
      const linkElement = linkRef.current;

      if (linkElement) {
        const searchParams = document.location.search;
        linkElement.href += searchParams;
      }
    }

    pushUTM();
  }, []); // Пустой массив зависимостей означает, что эффект выполнится только один раз, после монтирования компонента.

  const buttonRef = useRef(null);

  useEffect(() => {
    const dataLayerPush = (nameconv) => {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: nameconv,
      });
    };

    const handleClick = () => {
      dataLayerPush("going_website");
    };

    const button = buttonRef.current;

    if (button) {
      button.addEventListener("click", handleClick);

      return () => {
        button.removeEventListener("click", handleClick);
      };
    }
  });

  return (
    <>
      {!showConditions ? (
        <>
          <div className={posterContainer}>
            <img src={imageSrc} className={styles.image} alt="" />
          </div>
          <div className={classNames(optionsContainer, styles.before)}>
            <div
              className={classNames({
                [styles.resultPosterContainer]: true,
                [styles.onScrollOption]: offset,
              })}
            >
              <div className={styles.contentWrapper}>
                <div className={styles.score}>{score}</div>
                <Title value={title} />
                {text}
              </div>

              <div className={styles.buttonBox}>
                <a
                  href="https://business.taximaxim.com/id/id-ID/"
                  ref={linkRef}
                  target="_blank"
                  rel="noreferrer"
                  id="going_website"
                >
                  <Button
                    value="Kirimkan permohonan"
                    id="going_website_two"
                    ref={buttonRef}
                    className={styles.buttonPresent}
                  />
                </a>
              </div>
              <div className={styles.buttonBoxOutline}>
                <Button
                  value="Ikuti kuis sekali lagi"
                  className={styles.outline}
                  onClick={handlerBack}
                  id="refresh"
                />
              </div>

              <div
                className={classNames(
                  styles.contentWrapper,
                  styles.socialWrapper
                )}
              >
                <Paragraph value="Bagikan" />
                <div className={styles.social}>
                  <FacebookShareButton
                    url={SITE_URL}
                    title="Layanan Maxim beroperasi di 250+ kota di Indonesia dan tengah mencari mitra yang siap membuka bisnis sendiri. Saya sudah mengetahui kesiapan saya dalam mengelola bisnis transportasi online. Anda pun bisa mencobanya."
                    image={ImageURL}
                    hashtag="#Maxim"
                    id="facebook"
                  >
                    <FacebookIcon
                      size={48}
                      round={true}
                      className={classNames(styles.icon, styles.facebook)}
                    />
                  </FacebookShareButton>

                  <WhatsappShareButton
                    url={SITE_URL}
                    title="Layanan Maxim beroperasi di 250+ kota di Indonesia dan tengah mencari mitra yang siap membuka bisnis sendiri. Saya sudah mengetahui kesiapan saya dalam mengelola bisnis transportasi online. Anda pun bisa mencobanya."
                    image={ImageURL}
                    id="whatsapp"
                  >
                    <WhatsappIcon
                      size={48}
                      round={true}
                      className={classNames(styles.icon, styles.whatsapp)}
                    />
                  </WhatsappShareButton>

                  <div id="sharelink">
                    <IconButton
                      onClick={testClick}
                      color="primary"
                      className={styles.share}
                    >
                      <ShareIcon />
                    </IconButton>
                    <Snackbar
                      message="Tautan berhasil tersalin"
                      anchorOrigin={{ vertical: "top", horizontal: "center" }}
                      autoHideDuration={1000}
                      onClose={() => setOpen(false)}
                      open={open}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {copySuccess}
        </>
      ) : (
        <>
          <div className={styles.posterContainer}>
            <img src={imageSrc} className={styles.image} alt="" />
          </div>
          <div className={styles.resultContainer} m2>
            <div
              className={classNames({
                [styles.resultContainerBox]: true,
                [styles.onScrollOption]: offset,
              })}
            >
              <div>
                <Paragraph value="Cupom de desconto válido entre 22/07/2024 e 21/08/2024 e cumulável apenas com outros cupons de desconto, conforme regras das plataforma de vendas. Cupom válido para os 53 primeiros clientes que o utilizarem." />
                <Button
                  className={styles.buttonConditions}
                  value="Kembali"
                  onClick={() => {
                    setShowConditions(false);
                  }}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Result;
