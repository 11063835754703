const questions = [
  // 1 Слайд \\
  {
    questionText: "Bagaimana pelanggan Anda memesan perjalanan?",
    answerOptions: [
      {
        answerText: "Di situs. Bisnis mana pun pasti punya yang seperti ini!",
        answerResult:
          "Mitra bisnis Maxim langsung memperoleh situs dan aplikasi untuk pelanggan — aplikasi ini sudah diunduh 135+ juta kali di market.",
        result: false,
      },
      {
        answerText: "Kami menjaring klien di jalan.",
        answerResult:
          "Mitra bisnis Maxim langsung memperoleh situs dan aplikasi untuk pelanggan — aplikasi ini sudah diunduh 135+ juta kali di market.",
        result: false,
      },
      {
        answerText:
          "Di aplikasi. Semua orang pastinya punya ponsel pintar sekarang.",
        answerResult:
          "Mitra bisnis Maxim langsung memperoleh situs dan aplikasi untuk pelanggan — aplikasi ini sudah diunduh 135+ juta kali di market.",
        result: true,
      },
    ],
    image: "images/1.webp",
  },

  // 2 слайд
  {
    questionText:
      "Saat ini, ada lebih sedikit order daripada biasanya. Anda perlu menarik lebih banyak pelanggan. Apa yang akan Anda lakukan?",
    answerOptions: [
      {
        answerText: "Tidak ada. Biarkan mereka yang menemukan saya.",
        answerResult:
          "Untuk menarik pelanggan, Anda perlu melakukan kampanye periklanan. Mitra bisnis Maxim akan memperoleh akses ke katalog web berikut seperangkat materi siap pakai untuk digunakan sebagai hadiahnya.",
        result: false,
      },
      {
        answerText:
          "Saya akan turun ke jalan dan menjelaskan tentang layanan saya kepada orang-orang. Seseorang pasti akan memesan perjalanan.",
        answerResult:
          "Untuk menarik pelanggan, Anda perlu melakukan kampanye periklanan. Mitra bisnis Maxim akan memperoleh akses ke katalog web berikut seperangkat materi siap pakai untuk digunakan sebagai hadiahnya.",
        result: false,
      },
      {
        answerText:
          "Saya akan mengadakan kampanye periklanan. Saya akan menggunakan beragam platform dengan biaya yang hemat.",
        answerResult:
          "Untuk menarik pelanggan, Anda perlu melakukan kampanye periklanan. Mitra bisnis Maxim akan memperoleh akses ke katalog web berikut seperangkat materi siap pakai untuk digunakan sebagai hadiahnya.",
        result: true,
      },
    ],
    image: "images/2.webp",
  },

  // 3 слайд
  {
    questionText:
      "Peladen milik pesaing mengalami kerusakan dan aplikasi berhenti menerima order. Bagaimana cara untuk menghindari situasi seperti ini di pihak Anda?",
    answerOptions: [
      {
        answerText:
          "Tidak ada cara apa pun. Semua aplikasi mengalami gangguan dari waktu ke waktu, terutama ketika bebannya berat.",
        answerResult:
          "Dukungan teknis akan meminimalkan kemungkinan terjadinya gangguan. Kinerja aplikasi para mitra bisnis Maxim dipantau sepanjang hari.",
        result: false,
      },
      {
        answerText: "Apa lagi ini?! Apakah juga harus merekrut ahli TI?!",
        answerResult:
          "Dukungan teknis akan meminimalkan kemungkinan terjadinya gangguan. Kinerja aplikasi para mitra bisnis Maxim dipantau sepanjang hari.",
        result: false,
      },
      {
        answerText:
          "Saya akan memastikan bahwa dukungan teknis menjadi klausul wajib dalam perjanjian pengembangan aplikasi.",
        answerResult:
          "Dukungan teknis akan meminimalkan kemungkinan terjadinya gangguan. Kinerja aplikasi para mitra bisnis Maxim dipantau sepanjang hari.",
        result: true,
      },
    ],
    image: "images/3.webp",
  },

  // 4 слайд
  {
    questionText:
      "Tampaknya, jumlah pelanggan menjadi terlalu banyak: mereka mengeluhkan waktu tunggu yang lama. Kita perlu pengemudi. Dengan cara apa Anda akan menarik mereka?",
    answerOptions: [
      {
        answerText: "Tidak akan ada yang dapat menolak karisma saya.",
        answerResult:
          "Penting bagi para pengemudi untuk dapat mengelola waktu mereka dan menghasilkan uang dengan layak — hal ini menjadi mungkin berkat keseimbangan antara harga dan komisi. Selain itu, para pengemudi Maxim juga dapat memanfaatkan aplikasi modern untuk bekerja dengan order.",
        result: false,
      },
      {
        answerText:
          "Saya akan menjadikan perjalanan sepuluh kali lipat lebih mahal dibandingkan pesaing. Dengan begitu, pengemudi akan menghasilkan uang puluhan kali lipat lebih banyak. Bukankah begitu?",
        answerResult:
          "Penting bagi para pengemudi untuk dapat mengelola waktu mereka dan menghasilkan uang dengan layak — hal ini menjadi mungkin berkat keseimbangan antara harga dan komisi. Selain itu, para pengemudi Maxim juga dapat memanfaatkan aplikasi modern untuk bekerja dengan order.",
        result: false,
      },
      {
        answerText:
          "Saya akan menarik mereka dengan sejumlah keuntungan: jadwal yang bebas, ketiadaan komisi.",
        answerResult:
          "Penting bagi para pengemudi untuk dapat mengelola waktu mereka dan menghasilkan uang dengan layak — hal ini menjadi mungkin berkat keseimbangan antara harga dan komisi. Selain itu, para pengemudi Maxim juga dapat memanfaatkan aplikasi modern untuk bekerja dengan order.",
        result: true,
      },
    ],
    image: "images/4.webp",
  },

  // 5 слайд
  {
    questionText:
      "Wah, ada yang tidak beres. Aplikasi pelanggan tidak berfungsi dan mereka meninggalkan ulasan negatif. Siapa yang akan menjawab pengaduan ini?",
    answerOptions: [
      {
        answerText: "Saya yang akan menjawab saat ada waktu luang.",
        answerResult:
          "Ada ide yang lebih baik: Maxim menghubungkan seluruh mitra bisnis dengan kantor jarak jauh. Para spesialis akan menangani pengaduan 24/7.",
        result: false,
      },
      {
        answerText:
          "Tidak akan ada yang menjawab. Biarkan mereka yang mengurus hal ini sendiri.",
        answerResult:
          "Ada ide yang lebih baik: Maxim menghubungkan seluruh mitra bisnis dengan kantor jarak jauh. Para spesialis akan menangani pengaduan 24/7.",
        result: false,
      },
      {
        answerText:
          "Saya akan merekrut pegawai. Hanya saja mungkin biayanya mahal untuk ini.",
        answerResult:
          "Ada ide yang lebih baik: Maxim menghubungkan seluruh mitra bisnis dengan kantor jarak jauh. Para spesialis akan menangani pengaduan 24/7.",
        result: true,
      },
    ],
    image: "images/5.webp",
  },
];

export default questions;
